function EmailSupportBox() {
  return (
    <p className="subtitle2 text-center text-gray-dark mt-6">
      Per richieste di supporto scrivere a{" "}
      <a href="mailto:my360help@networkdigital360.it">
        my360help@networkdigital360.it
      </a>
    </p>
  );
}

export default EmailSupportBox;
