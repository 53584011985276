import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useAuth } from "context/Auth";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import logout from "helpers/authhelper";
import { useApolloClient } from "@apollo/client";
import { useRouter } from "next/router";

export default function ProfileMenu() {
  const { user } = useAuth();
  const client = useApolloClient();
  const router = useRouter();
  // TODO Utilizzare il Link per la pagina profilo e capire come gestire la chiusura del menù
  return (
    <div className="flex-shrink-0">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="text-right inline-flex items-center w-full">
            <div>
              <p className="text-sm md:text-base text-gray-dark">{`${
                user.attributes?.given_name || ""
              } ${user.attributes?.family_name || ""}`}</p>
              <p className="subtitle2 text-gray-light hidden md:block">
                {user.attributes.email}
              </p>
            </div>
            <KeyboardArrowDownIcon
              className="ml-2 text-primary-dark"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1">
              <div className="md:hidden">
                <Menu.Item>
                  <p className="subtitle2 text-gray-light px-2 pt-2">
                    {user.attributes.email}
                  </p>
                </Menu.Item>
              </div>
              <Menu.Item>
                <button
                  onClick={() => router.push("/profilo")}
                  className="text-gray-900 group flex rounded-md items-center w-full px-2 py-2 text-sm border-b border-white-dark"
                >
                  Il tuo profilo
                </button>
              </Menu.Item>
              <Menu.Item>
                <button
                  className="text-gray-900 group flex rounded-md items-center w-full px-2 py-2 text-sm"
                  onClick={() => {
                    logout(client);
                    router.push("/manifestazioni", "/");
                  }}
                >
                  Logout
                </button>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
