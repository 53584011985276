import React, { useState, MouseEventHandler } from "react";
import { useAppContext } from "context/App";
import { BreadcrumbItem } from "context/App/App";
import Link from "next/link";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SidebarModal from "components/SidebarModal";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

function BreadCrumb({
  links,
  handleOpenSidebar,
  openSidebar,
}: {
  links: BreadcrumbItem[];
  handleOpenSidebar: Function;
  openSidebar: boolean;
}) {
  if (!links || links?.length === 0) {
    return null;
  }
  const isMainPage = links.length === 1;
  return (
    <ul className="flex space-x-2 items-center lg:max-w-[calc(100vw-320px)] overflow-hidden">
      <li className="lg:hidden mr-2">
        <button onClick={handleOpenSidebar as MouseEventHandler}>
          {openSidebar ? (
            <CloseOutlinedIcon className="w-5 text-primary-dark" />
          ) : (
            <MenuOutlinedIcon className="w-5 text-primary-dark" />
          )}
        </button>
      </li>
      {links.map((link, index) => {
        const isPrimary = !isMainPage && index !== links.length - 1;
        return (
          <React.Fragment key={link.title}>
            <li
              className={`text-sm md:text-6xl ${
                index === links.length - 1
                  ? "whitespace-nowrap overflow-hidden text-ellipsis"
                  : "flex-shrink-0"
              } ${isPrimary ? "text-primary font-bold" : "text-gray-darker"} ${
                link.hideOnMobile ? "hidden md:block" : ""
              }`}
            >
              <Link href={link.href} as={link.as}>
                <a className={`${isPrimary ? "font-bold" : "font-normal"}`}>
                  {link.title}
                </a>
              </Link>
            </li>
            {index !== links.length - 1 && (
              <li
                className={`text-gray-light text-[16px] ${
                  link.hideOnMobile ? "hidden md:block" : ""
                }`}
              >
                <ArrowForwardIosIcon fontSize="inherit" />
              </li>
            )}
          </React.Fragment>
        );
      })}
    </ul>
  );
}

function Navbar() {
  const { appNavigation } = useAppContext();
  const [open, setOpen] = useState(false);
  const handleOpenSidebar = () => {
    setOpen(!open);
  };
  const closeSidebar = () => {
    setOpen(false);
  };

  return (
    <>
      <nav className="fixed w-full lg:max-w-[calc(100vw-320px)] top-[63px] md:top-[70px] flex items-center px-4 lg:px-8 py-2 lg:py-4 border-b border-white-dark z-20 bg-white">
        <BreadCrumb
          links={appNavigation?.breadcrumb}
          handleOpenSidebar={handleOpenSidebar}
          openSidebar={open}
        />
      </nav>
      <SidebarModal isOpen={open} closeModal={closeSidebar} />
    </>
  );
}

export default Navbar;
