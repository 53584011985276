import React, { useContext, useState } from "react";
import { AppContext, Navigation } from "./App";

const appContext = React.createContext<AppContext>({
  appNavigation: {},
  setAppNavigation: null,
  openCookiebar: false,
});

export const useAppContext = (): AppContext => useContext(appContext);

const useAppProvider = (): AppContext => {
  const [appNavigation, setAppNavigation] = useState<Navigation>(null);
  const [openCookiebar, setOpenCookiebar] = useState<boolean>(false);

  return {
    appNavigation,
    setAppNavigation,
    openCookiebar,
    setOpenCookiebar,
  };
};

export function AppProvider({ children }) {
  const app = useAppProvider();
  return <appContext.Provider value={app}>{children}</appContext.Provider>;
}
