import React, { ChangeEventHandler, ReactElement } from "react";

const createSwitch = (
  required: boolean,
  name: string,
  checked: boolean,
  onChange: ChangeEventHandler
) => {
  if (required) {
    return (
      <span className="cookie-bar__options__options-box__item__required">
        Strettamente necessari
      </span>
    );
  }
  return (
    <div className="cookie-bar__switch">
      <label htmlFor={`${name}-checkbox`} className="relative">
        <input
          id={`${name}-checkbox`}
          type="checkbox"
          checked={checked}
          onChange={onChange || null}
        />
        <span className="cookie-bar__slider round" />
      </label>
    </div>
  );
};

function CookieOption({
  title,
  required,
  name,
  checked,
  onChange,
  children,
}: {
  title: string;
  required?: boolean;
  name: string;
  checked?: boolean;
  onChange?: ChangeEventHandler;
  children: ReactElement;
}) {
  return (
    <div className="cookie-bar__options__options-box__item">
      <div className="cookie-bar__options__options-box__item__input-container">
        <h4 className="cookiebar-text-bold cookiebar-text-size-2">{title}</h4>
        {createSwitch(required, name, checked, onChange)}
      </div>
      {children}
    </div>
  );
}

export default CookieOption;

CookieOption.defaultProps = {
  required: false,
  checked: false,
  onChange: () => {},
};
