import * as Sentry from "@sentry/nextjs";

/**
 * funzione di helper per catturare gli errori con sentry.
 *
 * @param {String} key chiave scope
 * @param {String} value valore scope
 * @param {Array} errors lista di errori
 */
const captureException = ({ key, value, error, extra }) => {
  const scope = new Sentry.Scope();
  scope.setTag(key, value);
  scope.setExtra("data", extra);
  const listError = Array.isArray(error) ? error : error?.errors;
  if (typeof error === "string") {
    Sentry.captureException(new Error(error), scope);
    return;
  }
  if (Array.isArray(listError)) {
    listError.forEach((errorItem) => {
      if (errorItem?.message) {
        Sentry.captureException(new Error(errorItem.message), scope);
      }
      Sentry.captureException(
        new Error(`Error tracing issue: ${JSON.stringify(errorItem)}`),
        scope
      );
    });
    return;
  }
  if (error?.message) {
    Sentry.captureException(new Error(error.message), scope);
    return;
  }
  Sentry.captureException(
    new Error(
      `Error tracing issue, error message not found: ${JSON.stringify(error)}`
    ),
    scope
  );
};

export default captureException;
