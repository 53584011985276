import React from "react";
import logo from "assets/img/logo.svg";
import Image from "next/image";
import imgLoader from "helpers/urlHelper";
import ProfileMenu from "components/MainNavbar/ProfileMenu";

function MainNavbar() {
  return (
    <div className="fixed z-30 top-0 w-full">
      <nav className="bg-white w-full flex justify-between items-center px-4 py-[14px] md:px-8">
        <div className="min-w-[100px] min-h-[32px] flex mr-4">
          <Image
            objectFit="contain"
            objectPosition="left"
            src={logo}
            height="32"
            alt="logo"
            loader={imgLoader}
          />
        </div>
        <ProfileMenu />
      </nav>
      <div className="bg-gradient-to-r from-gradient2_from to-gradient2_to h-[3px] w-full z-20" />
    </div>
  );
}

export default MainNavbar;
