import clsx from "clsx";
import React, { ButtonHTMLAttributes, MouseEventHandler } from "react";
import Loader from "components/Loader";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: MouseEventHandler;
}

function Button({
  type,
  label,
  className,
  loading,
  disabled,
  onClick,
}: ButtonProps) {
  return (
    <button
      disabled={loading || disabled}
      className={clsx("p-2 text-center", className)}
      type={type}
      onClick={onClick}
    >
      {!loading ? label : <Loader color="text-white" />}
    </button>
  );
}

Button.defaultProps = {
  className: null,
};

export default Button;
