import { ReactNode } from "react";
import clsx from "clsx";

export interface componentProps {
  children: ReactNode;
  className?: string;
}

function Container({ children, className }: componentProps) {
  return (
    <main className={clsx("container mx-auto px-4 md:px-0", className)}>
      {children}
    </main>
  );
}

Container.defaultProps = {
  className: null,
};

export default Container;
