import { ImageLoader, ImageLoaderProps } from "next/image";

const isUrl = (str: string) => {
  try {
    const parsedUrl = new URL(str);
    return !!parsedUrl;
  } catch (error) {
    return false;
  }
};

const imgLoader: ImageLoader = ({ src }: ImageLoaderProps) => {
  if (src.includes("next/static") || isUrl(src)) {
    // Si tratta di un immagine statica, ritorno qui
    return src;
  }
  // Uso la CDN per risolvere l'URL
  return src ? `${process.env.NEXT_PUBLIC_CDN_URL}/${src}` : null;
};

export default imgLoader;
