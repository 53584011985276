import Link from "next/link";

function GoToHome() {
  return (
    <p>
      Sei già stato autenticato,{" "}
      <Link href="/manifestazioni" as="/" passHref>
        <a className="text-primary">ritorna alla home</a>
      </Link>
    </p>
  );
}

export default GoToHome;
