import { ReactNode } from "react";
import Image from "next/image";
import Container from "components/Layout/Container";
import logo from "assets/img/logo.svg";
import imgLoader from "helpers/urlHelper";
import { useAuth } from "context/Auth";
import { useRouter } from "next/router";
import CookieBar from "components/Coookiebar/cookieBar";
import { useAppContext } from "context/App";

export interface componentProps {
  children: ReactNode;
  title?: string;
}

function AuthenticatorPageWrapper({ children, title }: componentProps) {
  const { changeAuthState } = useAuth();
  const { setOpenCookiebar } = useAppContext();
  const router = useRouter();

  const returnToHome = () => {
    if (/^\/passwordRecovery/g.test(router.asPath)) {
      router.push("/manifestazioni", "/");
    }
    changeAuthState("signin");
  };

  return (
    <Container className="grid grid-cols-1 place-content-center mx-auto pt-8 sm:pt-20">
      <button onClick={returnToHome}>
        {" "}
        <Image src={logo} loader={imgLoader} height="40" alt="my360" />
      </button>

      {title && (
        <p className="text-2xl md:text-xl text-center text-gray-darker font-bold mb-8 mt-4">
          {title}
        </p>
      )}
      {children}
      <CookieBar />
      <button
        onClick={() => setOpenCookiebar(true)}
        className="cookie-center fixed left-4 md:left-8 bottom-8 subtitle2 text-gray-darker cursor-pointer"
      >
        Cookie Center
      </button>
    </Container>
  );
}

export default AuthenticatorPageWrapper;
