import React, { MouseEventHandler, KeyboardEventHandler } from "react";
import Link from "next/link";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import clsx from "clsx";
import { useAppContext } from "context/App";
import Divider from "components/Divider";

interface componentProps {
  className?: string;
  closeModal?: Function;
}

function Sidebar({ className, closeModal }: componentProps) {
  const { appNavigation, setOpenCookiebar } = useAppContext();

  return (
    <aside
      className={clsx(
        "p-8 lg:w-[320px] lg:min-w-[320px] border-r-2 min-h-full",
        className
      )}
    >
      <ul className="space-y-4">
        <li>
          <Link passHref href="/manifestazioni" as="/">
            <a
              onClick={closeModal as MouseEventHandler<HTMLAnchorElement>}
              onKeyDown={closeModal as KeyboardEventHandler<HTMLAnchorElement>}
              role="button"
              tabIndex={0}
              className={`text-6xl  font-normal flex items-center ${
                appNavigation?.sidebar === "manifestazioni"
                  ? "text-primary"
                  : "text-gray-darker"
              }`}
            >
              <CalendarMonthOutlinedIcon className="w-5 inline-block align-middle mr-1" />
              Manifestazioni
            </a>
          </Link>
        </li>
        <li>
          <Link href="/agenda" passHref>
            <a
              onClick={closeModal as MouseEventHandler<HTMLAnchorElement>}
              onKeyDown={closeModal as KeyboardEventHandler<HTMLAnchorElement>}
              role="button"
              tabIndex={0}
              className={`text-6xl font-normal flex items-center ${
                appNavigation?.sidebar === "agenda"
                  ? "text-primary"
                  : "text-gray-darker"
              }`}
            >
              <EventAvailableOutlinedIcon className="w-5 inline-block align-middle mr-1" />
              La tua agenda
            </a>
          </Link>
        </li>
        <li>
          <Link href="/attestati" passHref>
            <a
              onClick={closeModal as MouseEventHandler<HTMLAnchorElement>}
              onKeyDown={closeModal as KeyboardEventHandler<HTMLAnchorElement>}
              role="button"
              tabIndex={0}
              className={`text-6xl font-normal flex items-center ${
                appNavigation?.sidebar === "attestati"
                  ? "text-primary"
                  : "text-gray-darker"
              }`}
            >
              <WorkspacePremiumOutlinedIcon className="w-5 inline-block align-middle mr-1" />
              I tuoi attestati
            </a>
          </Link>
        </li>
        <Divider />
        <li>
          <Link href="/supporto" passHref>
            <a
              onClick={closeModal as MouseEventHandler<HTMLAnchorElement>}
              onKeyDown={closeModal as KeyboardEventHandler<HTMLAnchorElement>}
              role="button"
              tabIndex={0}
              className={`text-6xl font-normal flex items-center ${
                appNavigation?.sidebar === "supporto"
                  ? "text-primary"
                  : "text-gray-darker"
              }`}
            >
              <InfoOutlinedIcon className="w-5 inline-block align-middle mr-1" />
              Supporto
            </a>
          </Link>
        </li>
      </ul>
      <button
        onClick={() => setOpenCookiebar(true)}
        className="cookie-center fixed bottom-8 subtitle2 text-gray-darker cursor-pointer"
      >
        Cookie Center
      </button>
    </aside>
  );
}

export default Sidebar;
