import { Auth } from "aws-amplify";
import captureException from "helpers/sentryHelper";

const logout = async (client) => {
  try {
    await Auth.signOut().then(() => {
      client.clearStore();
    });
  } catch (error) {
    captureException({
      key: "component",
      value: "logout",
      error,
      extra: null,
    });
    console.error("error signing out: ", error);
  }
};

export default logout;
