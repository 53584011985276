import React, { useEffect } from "react";
import { Auth, API } from "aws-amplify";
import { useRouter } from "next/router";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "components/Layout/Box";
import Input from "components/Forms/Input";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import Button from "components/Forms/Button";
import EmailSupportBox from "components/Auth/EmailSupportBox";
import captureException from "helpers/sentryHelper";
import { AuthViewProps } from "components/Auth/CustomAuthenticator/types";
import { useAuth } from "context/Auth";
import GoToHome from "components/GoToHome";
import AuthenticatorPageWrapper from "components/Auth/AuthenticatorPageWrapper";
import PageWrapper from "components/Layout/PageWrapper";

const schema = yup.object({
  email: yup.string().required("Campo Richiesto.").email("Email non valida."),
});

function PasswordRecovery({
  changeAuthState,
  isResetPassword,
  userEmail,
}: AuthViewProps) {
  const { asPath, push, query, isReady } = useRouter();
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: isResetPassword && userEmail ? userEmail : "",
    },
  });

  /**
   * Questa funzione viene chiamata quando l'utente è in stato
   * di ForceChangePassword
   */
  const handleForceChangePassword = async (email) => {
    try {
      await API.post("ResendPwApi", "/resendPassword", {
        body: { data: { email: email.toLowerCase() } },
      });
      changeAuthState("passwordrecovered");
    } catch (err) {
      setError("email", {
        message: "Qualcosa è andato storto, riprova più tardi.",
      });
      captureException({
        key: "component",
        value: "passwordRecovery",
        error: err,
        extra: {
          action: "resend-password",
          email,
        },
      });
    }
  };

  const { currentAuthState, user, setUserEmail } = useAuth();
  const tryRecoverPassword: SubmitHandler<FieldValues> = async (data) => {
    try {
      const isPasswordRecovery = /^\/passwordRecovery/g.test(asPath);
      let redirectTo =
        asPath.length > 0 && asPath !== "/" && !isPasswordRecovery
          ? asPath
          : null;

      if (isPasswordRecovery && query.fromDiretta) {
        redirectTo = "/?fromDiretta=true";
      }

      await Auth.forgotPassword(data.email.toLowerCase(), {
        redirectTo,
      });

      if (isPasswordRecovery) {
        push("/manifestazioni", "/");
      }

      changeAuthState("passwordrecovered");
    } catch (error) {
      switch (error?.name) {
        case "NotAuthorizedException":
          if (
            error?.message ===
            "User password cannot be reset in the current state."
          ) {
            await handleForceChangePassword(data.email);
            break;
          } else {
            captureException({
              key: "component",
              value: "passwordRecovery",
              error,
              extra: {
                action: "try-recover-password",
                data: JSON.stringify(data),
              },
            });
            setError("email", {
              message: "Qualcosa è andato storto, riprova più tardi",
            });
          }
          break;
        case "UserNotFoundException":
          setError("email", {
            message: "Utente inesistente",
          });
          break;
        case "LimitExceededException":
        case "QuotaExceededError":
          setError("email", {
            message: "Hai superato il limite di tentativi. Riprova più tardi",
          });
          break;
        case "InvalidParameterException":
          if (
            error?.message ===
            "Cannot reset password for the user as there is no registered/verified email or phone_number"
          ) {
            setUserEmail(data.email.toLowerCase());
            changeAuthState("resendconfirmationcode");
            break;
          }
          captureException({
            key: "component",
            value: "passwordRecovery",
            error,
            extra: {
              action: "try-recover-password-invalid-parameter",
              data: JSON.stringify(data),
            },
          });
          setError("email", {
            message: "Qualcosa è andato storto, riprova più tardi",
          });
          break;
        default:
          captureException({
            key: "component",
            value: "passwordRecovery",
            error,
            extra: {
              action: "try-recover-password",
              data: JSON.stringify(data),
            },
          });
          setError("email", {
            message: "Qualcosa è andato storto, riprova più tardi",
          });
          break;
      }
    }
  };

  useEffect(() => {
    if (isReady) {
      const { email } = query;
      reset({ email: email as string });
    }
  }, [query, isReady]);

  return (
    <div>
      {currentAuthState === "signedin" && user ? (
        <PageWrapper>
          <GoToHome />
        </PageWrapper>
      ) : (
        <AuthenticatorPageWrapper title="Reimposta la tua password">
          <Box className="w-full sm:w-1/2 lg:w-1/3 max-w-[400px] mx-auto">
            <form
              method="POST"
              className="w-full"
              onSubmit={handleSubmit(tryRecoverPassword)}
            >
              <Input
                name="email"
                register={register}
                className="w-full"
                type="email"
                placeholder="Inserisci la tua email"
                label="Email *"
                error={errors?.email?.message}
              />
              <Button
                className="w-full btn btn-primary mt-4"
                type="submit"
                label="Reimposta password"
                loading={isSubmitting}
              />
            </form>
          </Box>
          <EmailSupportBox />
        </AuthenticatorPageWrapper>
      )}
    </div>
  );
}

export default PasswordRecovery;
