import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import Sidebar from "components/Sidebar";

interface componentProps {
  isOpen: boolean;
  closeModal: Function;
}

function SidebarModal({ isOpen, closeModal }: componentProps) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="inset-0 top-[104px] md:top-[112px] fixed z-10 overflow-y-auto lg:hidden"
        onClose={() => closeModal()}
      >
        <div className="px-[16px] md:px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-black bg-opacity-40" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="bg-white fixed inset-0 top-[104px] md:top-[112px] md:right-auto w-full max-w-xs">
              <Sidebar closeModal={closeModal} />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default SidebarModal;
