import React from "react";
import * as yup from "yup";
import Box from "components/Layout/Box";
import Input from "components/Forms/Input";
import Button from "components/Forms/Button";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import { yupResolver } from "@hookform/resolvers/yup";
import captureException from "helpers/sentryHelper";
import { useAuth } from "context/Auth";

const schema = yup.object({
  password: yup.string().required("Campo richiesto."),
  confirmpassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Le password devono essere uguali."),
});

function ForceNewPassword() {
  const { user } = useAuth();
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const tryCompletNewPassword: SubmitHandler<FieldValues> = async ({
    password,
  }) => {
    try {
      await Auth.completeNewPassword(user, password);
    } catch (error) {
      console.error(error);
      switch (error?.name) {
        case "InvalidPasswordException":
          setError("confirmpassword", {
            message:
              "La password non è valida. La password deve contenere almeno 8 caratteri, contenere almeno un numero, una lettera minuscola e una lettera maiuscola",
          });
          break;
        default:
          captureException({
            key: "component",
            value: "forcenewpassword",
            error,
            extra: null,
          });
          setError("confirmpassword", {
            message: "Si è verificato un errore, riprova più tardi",
          });
      }
    }
  };

  return (
    <Box className="w-full sm:w-1/2 lg:w-1/3 mx-auto max-w-[400px]">
      <form
        method="POST"
        className="w-full"
        onSubmit={handleSubmit(tryCompletNewPassword)}
      >
        <Input
          name="password"
          className="w-full"
          type="password"
          register={register}
          error={
            typeof errors?.password?.message === "string"
              ? errors?.password?.message
              : ""
          }
          placeholder="Inserisci password"
          autoComplete="new-password"
          label="Password *"
          withHideIcon
        />
        <Input
          name="confirmpassword"
          className="w-full"
          type="password"
          register={register}
          error={
            typeof errors?.confirmpassword?.message === "string"
              ? errors?.confirmpassword?.message
              : ""
          }
          placeholder="Conferma password"
          autoComplete="new-password"
          label="Conferma password *"
          withHideIcon
        />
        <Button
          loading={isSubmitting}
          className="w-full btn btn-primary mt-4"
          type="submit"
          label="Conferma"
        />
      </form>
    </Box>
  );
}

export default ForceNewPassword;
