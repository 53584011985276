import React, { MouseEventHandler } from "react";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

interface componentProps {
  handleClickShowPassword: MouseEventHandler;
  show: boolean;
}

function ShowPasswordIcon({ handleClickShowPassword, show }: componentProps) {
  return (
    <div className="absolute right-3 top-9 text-gray-light">
      <button
        className="outline-0"
        type="button"
        onClick={handleClickShowPassword}
      >
        {show ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
      </button>
    </div>
  );
}

export default ShowPasswordIcon;
