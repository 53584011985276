import React from "react";
import Box from "components/Layout/Box";
import EmailSupportBox from "components/Auth/EmailSupportBox";

export interface ThankYouProps {
  thankYouType: "signup" | "password-recover";
  userEmail?: string;
}

function ThankYou({ thankYouType, userEmail }: ThankYouProps) {
  return (
    <>
      <Box className="w-full sm:w-1/2 lg:w-1/3 max-w-[400px] sm:min-w-[400px] mx-auto">
        {thankYouType === "signup" ? (
          <span className="text-gray-darker text-6xl break-words">
            Grazie per esserti registrato, ti abbiamo inviato un&apos;email
            all&apos;indirizzo {userEmail} con le istruzioni per completare la
            registrazione.
          </span>
        ) : (
          <span className="text-gray-darker text-6xl">
            Riceverai un email con il link per recuperare la password
          </span>
        )}
      </Box>
      <EmailSupportBox />
    </>
  );
}

export default ThankYou;
