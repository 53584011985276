import { Amplify, I18n, Auth } from "aws-amplify";
import { translations } from "@aws-amplify/ui-react";
import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import captureException from "helpers/sentryHelper";

I18n.putVocabularies(translations);
I18n.setLanguage("it");

const amplifyConfig = {
  Auth: {
    region: "eu-west-1",
    userPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID,
    userPoolWebClientId: process.env.NEXT_PUBLIC_USER_WEB_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "PublicAPI",
        endpoint: process.env.NEXT_PUBLIC_AG,
        region: "eu-west-1",
      },
      {
        name: "ResendPwApi",
        endpoint: process.env.NEXT_PUBLIC_AG_PW_RESEND,
        region: "eu-west-1",
      },
    ],
  },
};

const configure = () => Amplify.configure(amplifyConfig);

const getClient = () => {
  const appsyncUrl = process.env.NEXT_PUBLIC_GRAPHQL;

  const appsyncConfig = {
    url: appsyncUrl,
    region: process.env.NEXT_PUBLIC_REGION || "eu-west-1",
    auth: {
      type: "AMAZON_COGNITO_USER_POOLS",
      jwtToken: async () => {
        try {
          return (await Auth.currentSession()).getIdToken().getJwtToken();
        } catch (error) {
          captureException({
            key: "component",
            value: "appconfig",
            error,
            extra: null,
          });
          return null;
        }
      },
    },
  };

  const appsyncLink = ApolloLink.from([
    createAuthLink(appsyncConfig),
    createSubscriptionHandshakeLink(
      appsyncConfig,
      createHttpLink({
        uri: appsyncUrl,
      })
    ),
  ]);

  const appsyncClient = new ApolloClient({
    link: appsyncLink,
    cache: new InMemoryCache(),
  });

  return appsyncClient;
};

export default { configure, getClient };
