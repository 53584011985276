import React, { ReactElement } from "react";

function CookieBarButton({ outlined, ...props }: any): ReactElement {
  const classes = outlined
    ? "cookie-bar__button cookie-bar__button--outlined"
    : "cookie-bar__button";
  return <div className={classes} {...props} />;
}

export default CookieBarButton;

CookieBarButton.defaultProps = {
  outlined: false,
};
