import React, { ReactNode } from "react";
import clsx from "clsx";

export interface componentProps {
  children: ReactNode;
  className?: string;
}

function Box({ children, className }: componentProps) {
  return (
    <div
      className={clsx("p-0 sm:p-8 sm:border sm:border-white-dark", className)}
    >
      {children}
    </div>
  );
}

Box.defaultProps = {
  className: null,
};

export default Box;
