import React, { MouseEventHandler } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "components/Layout/Box";
import Input from "components/Forms/Input";
import Button from "components/Forms/Button";
import EmailSupportBox from "components/Auth/EmailSupportBox";
import captureException from "helpers/sentryHelper";
import { FormProps } from "./types";

interface PasswordFormProps extends FormProps {
  email: string;
  goBack: MouseEventHandler<HTMLButtonElement>;
  recoverPassword: MouseEventHandler<HTMLButtonElement>;
  resetPassword: Function;
}

const schema = yup.object({
  password: yup.string().required("Campo richiesto."),
});

function PasswordLoginForm({
  email,
  onFormSubmit,
  goBack,
  recoverPassword,
  resetPassword,
}: PasswordFormProps) {
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const sendPassword = async (event) => {
    try {
      return await onFormSubmit(event);
    } catch (error) {
      switch (error.message) {
        case "NotAuthorizedException":
          setError("password", {
            message: "Password non corretta o utente inesistente",
          });
          break;
        case "TooManyAttempts":
          setError("password", {
            message: "Hai superato il limite di tentativi. Riprova più tardi",
          });
          break;
        case "PasswordResetRequiredException":
          resetPassword(email);
          break;
        case "UserNotConfirmedException":
        case "UserNotFoundException":
        case "UserLambdaValidationException":
          setError("password", {
            message: "Utente inesistente o non verificato",
          });
          break;
        default:
          setError("password", {
            message:
              "Si è verificato un errore durante il login, riprova più tardi",
          });
          captureException({
            key: "component",
            value: "passwordloginform",
            error,
            extra: null,
          });
          console.error("sendPassword", error);
          break;
      }
      return false;
    }
  };
  return (
    <>
      <p className="text-2xl md:text-xl text-center text-gray-darker font-bold mb-8 mt-4">
        Inserisci la password
      </p>
      <Box className="w-full sm:w-1/2 lg:w-1/3 mx-auto max-w-[400px]">
        <form
          className="space-y-4 w-full"
          method="POST"
          onSubmit={handleSubmit(sendPassword)}
        >
          <div className="flex max-w-full flex-wrap">
            <p className="text-gray-dark subtitle1 mr-2">{email}</p>
            <button
              className="text-primary-dark subtitle2 font-bold bg-transparent"
              onClick={goBack}
              type="button"
            >
              modifica
            </button>
          </div>
          <Input
            type="password"
            name="password"
            className="w-full"
            register={register}
            error={
              typeof errors?.password?.message === "string"
                ? errors?.password?.message
                : ""
            }
            placeholder="Inserisci la password"
            label="Password *"
            textActionLabel="Password dimenticata?"
            actionLabel={recoverPassword}
            withHideIcon
          />
          <Button
            className="w-full btn btn-primary"
            type="submit"
            label="Accedi"
            loading={isSubmitting}
          />
        </form>
      </Box>
      <EmailSupportBox />
    </>
  );
}

export default PasswordLoginForm;
