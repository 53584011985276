import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { checkUserEmail } from "controllers/registration";
import EmailForm from "components/Forms/EmailForm";
import PasswordLoginForm from "components/Forms/PasswordLoginForm";
import { Auth } from "aws-amplify";
import { AuthViewProps } from "../CustomAuthenticator/types";

function Login({
  changeAuthState,
  setUserEmail,
  userEmail,
  setUser,
}: AuthViewProps) {
  const { query, isReady } = useRouter();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState(userEmail || null);

  const checkUserExists = async (data) => {
    const response = await checkUserEmail(data.email.toLowerCase());
    if (response) {
      setEmail(data.email.toLowerCase());
      setStep(2);
      return;
    }
    setUserEmail(data.email.toLowerCase());
    changeAuthState("signup");
    // Non esiste, vado al signup
  };

  const goToEmailForm = () => {
    setStep(1);
  };

  const goToPasswordRecovery = () => {
    changeAuthState("passwordrecovery");
  };

  const goToPasswordReset = (emailUserToResetPassword: string) => {
    setUserEmail(emailUserToResetPassword.toLowerCase());
    changeAuthState("passwordreset");
  };

  /**
   * Se arrivo da form preiscrizione hubspot, l'email sarà prepolata
   */
  useEffect(() => {
    if (isReady && query.user && email === null) {
      setEmail(decodeURIComponent(query.user as string).toLowerCase());
    }
  }, [isReady, query.user, email]);

  const tryLogin = async (data) => {
    try {
      const user = await Auth.signIn(email.toLowerCase(), data.password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        // Setto il cognito Object in modo da poterlo usare nella finestra di update password
        setUser(user);
        changeAuthState("forcenewpassword");
      }
    } catch (error) {
      if (error.name) {
        if (
          error.name === "NotAuthorizedException" &&
          error.message === "Password attempts exceeded"
        ) {
          throw new Error("TooManyAttempts");
        }
        throw new Error(error.name);
      }
      throw new Error(error);
    }
  };

  return (
    <>
      {step === 1 && <EmailForm onFormSubmit={checkUserExists} email={email} />}
      {step === 2 && (
        <PasswordLoginForm
          recoverPassword={goToPasswordRecovery}
          resetPassword={goToPasswordReset}
          goBack={goToEmailForm}
          onFormSubmit={tryLogin}
          email={email}
        />
      )}
    </>
  );
}

export default Login;
