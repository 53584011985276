import React from "react";
import Sidebar from "components/Sidebar";
import Navbar from "components/Navbar";
import MainNavbar from "components/MainNavbar/Navbar";

function PageWrapper({ children }) {
  return (
    <>
      <MainNavbar />
      <div className="pt-[104px] md:pt-[112px] lg:pt-[127px] flex min-h-[calc(100vh-70px)] md:min-h-screen">
        <Sidebar className="hidden lg:block lg:fixed lg:left-0 lg:top-[70px]" />
        <main className="w-full lg:ml-[320px]">
          <Navbar />
          <section className="py-1 px-4 md:p-8">{children}</section>
        </main>
      </div>
    </>
  );
}

export default PageWrapper;
