import React from "react";
import Button from "./cookieBarButton";

function CookieBarContent({
  onAccept,
  changeView,
}: {
  onAccept: Function;
  changeView: Function;
}) {
  return (
    <>
      <div className="cookie-bar__logo-container">
        {/* eslint-disable @next/next/no-img-element */}
        <img
          className="cookie-bar__logo"
          alt="logo networkd360"
          src="https://cdnd360.it/networkdigital360/ND360_Logo_POS.svg"
        />
        {/* eslint-enable @next/next/no-img-element */}
        <h4>I tuoi contenuti, la tua privacy!</h4>
      </div>
      <div className="cookie-bar__presentation">
        <div className="cookie-bar__presentation__titles">
          <p>
            Su questo sito utilizziamo cookie tecnici necessari alla navigazione
            e funzionali all’erogazione del servizio. Utilizziamo i cookie anche
            per fornirti un’esperienza di navigazione sempre migliore, per
            facilitare le interazioni con le nostre funzionalità social e per
            consentirti di ricevere comunicazioni di marketing aderenti alle tue
            abitudini di navigazione e ai tuoi interessi.
          </p>
          <p>
            Puoi esprimere il tuo consenso cliccando su ACCETTA TUTTI I COOKIE.
            Chiudendo questa informativa, continui senza accettare.
          </p>
          <p>
            Potrai sempre gestire le tue preferenze accedendo al nostro COOKIE
            CENTER e ottenere maggiori informazioni sui cookie utilizzati,
            visitando la nostra
            <a
              target="_blank"
              href="https://www.networkdigital360.it/cookie"
              rel="noopener nofollow noreferrer"
            >
              <strong> COOKIE POLICY</strong>
            </a>
            .
          </p>
        </div>
        <div className="cookie-bar__presentation__buttons">
          <Button onClick={onAccept}>ACCETTA</Button>
          <Button outlined onClick={() => changeView("options")}>
            PIÙ OPZIONI
          </Button>
        </div>
      </div>
    </>
  );
}

export default CookieBarContent;
