import React from "react";
import { useRouter } from "next/router";
import { useAuth } from "context/Auth";
import PageWrapper from "components/Layout/PageWrapper";
import * as Sentry from "@sentry/nextjs";
import Login from "../Login";
import Signup from "../Signup";
import ThankYou from "../ThankYou";
import ResendConfirmationCode from "../ResendConfirmationCode";
import PasswordRecovery from "../../../pages/passwordRecovery";
import AuthenticatorPageWrapper from "../AuthenticatorPageWrapper";
import ForceNewPassword from "../ForceNewPassword";

function CustomAuthenticator({ children }) {
  const {
    currentAuthState,
    changeAuthState,
    userEmail,
    setUserEmail,
    user,
    setUser,
  } = useAuth();
  const { asPath } = useRouter();

  const whitelistRoutes = /^\/confirmEmail|\/forgotPassword/g;
  if (whitelistRoutes.test(asPath)) {
    return <AuthenticatorPageWrapper>{children}</AuthenticatorPageWrapper>;
  }

  if (/^\/passwordRecovery/g.test(asPath)) {
    return (
      <PasswordRecovery
        changeAuthState={changeAuthState}
        userEmail={userEmail}
      />
    );
  }

  if (currentAuthState === "signedin" && user) {
    Sentry.setUser({ id: user?.attributes?.sub });
    return <PageWrapper>{children}</PageWrapper>;
  }

  let page;
  let wrapperProps = {};
  switch (currentAuthState) {
    case "signin":
      page = (
        <Login
          changeAuthState={changeAuthState}
          setUserEmail={setUserEmail}
          userEmail={userEmail}
          setUser={setUser}
        />
      );
      break;
    case "signup":
      page = <Signup changeAuthState={changeAuthState} userEmail={userEmail} />;
      wrapperProps = {
        title: "Crea un account",
      };
      break;
    case "signedup":
      page = <ThankYou userEmail={userEmail} thankYouType="signup" />;
      wrapperProps = {
        title: "Crea un account",
      };
      break;
    case "passwordrecovery":
    case "passwordreset": {
      const isResetPassword = currentAuthState === "passwordreset";
      return (
        <PasswordRecovery
          changeAuthState={changeAuthState}
          isResetPassword={isResetPassword}
          userEmail={userEmail}
        />
      );
    }
    case "forcenewpassword":
      page = <ForceNewPassword />;
      wrapperProps = {
        title: "Cambia la tua password",
      };
      break;
    case "passwordrecovered":
      page = <ThankYou thankYouType="password-recover" />;
      wrapperProps = {
        title: "Reimposta la tua password",
      };
      break;
    case "resendconfirmationcode":
      page = <ResendConfirmationCode userEmail={userEmail} />;
      wrapperProps = {
        title: "Verifica la tua email",
      };
      break;
    default:
      return null;
  }

  return (
    <AuthenticatorPageWrapper {...wrapperProps}>
      {page}
    </AuthenticatorPageWrapper>
  );
}

export default CustomAuthenticator;
