import clsx from "clsx";
import { MouseEventHandler, useState } from "react";
import ShowPasswordIcon from "components/Forms/ShowPasswordIcon";
import { InputSimpleProps } from "../types";

export default function Input({
  register,
  name,
  label,
  textActionLabel,
  actionLabel,
  error,
  className,
  withHideIcon,
  type,
  readOnly,
  readOnlyValue,
  ...rest
}: InputSimpleProps) {
  const [inputType, setInputType] = useState(type);

  const showContent = (event) => {
    event.preventDefault();
    setInputType(inputType === type ? "text" : type);
  };

  return (
    <div
      className={clsx("form-control w-full", withHideIcon ? `relative` : null)}
    >
      {(label || actionLabel) && (
        <label className="label" htmlFor={name}>
          {label && <span className="label-text">{label}</span>}
          {textActionLabel && actionLabel && (
            <button
              className="label-text-alt text-primary-dark subtitle2 font-bold bg-transparent"
              onClick={actionLabel as MouseEventHandler}
              type="button"
            >
              {textActionLabel}
            </button>
          )}
        </label>
      )}
      {!readOnly ? (
        <input
          {...register(name)}
          className={clsx(
            "block input input-sm input-bordered w-full",
            className
          )}
          type={inputType}
          {...rest}
        />
      ) : (
        <p className="subtitle1 pb-2 pt-1">{readOnlyValue}</p>
      )}
      {withHideIcon && (
        <ShowPasswordIcon
          handleClickShowPassword={showContent}
          show={inputType === "text"}
        />
      )}
      {error && (
        <span className="text-xs text-red-700 inline-block mt-1">{error}</span>
      )}
    </div>
  );
}
