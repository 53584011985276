import React, { SetStateAction, useContext, useEffect, useState } from "react";
import { Auth, Hub } from "aws-amplify";
import captureException from "helpers/sentryHelper";
import * as Sentry from "@sentry/nextjs";

type AuthState =
  | "signin"
  | "signup"
  | "signedin"
  | "signedup"
  | "passwordrecovery"
  | "passwordreset"
  | "forcenewpassword"
  | "passwordrecovered"
  | "resendconfirmationcode";

type UserAttributes = {
  attributes: {
    given_name: string;
    family_name: string;
    email: string;
    sub: string;
  };
};
export interface AuthContext {
  currentAuthState: AuthState;
  user: UserAttributes;
  userEmail?: string;
  changeAuthState: React.Dispatch<SetStateAction<AuthState>>;
  setUserEmail: React.Dispatch<SetStateAction<string>>;
  setUser: React.Dispatch<SetStateAction<any>>;
  updateUserAttributes: any;
}

const authContext = React.createContext<AuthContext>({
  currentAuthState: null,
  userEmail: null,
  user: null,
  changeAuthState: null,
  setUserEmail: null,
  updateUserAttributes: null,
  setUser: null,
});

export const useAuth = (): AuthContext => useContext(authContext);

export const isAuthenticated = async () => {
  try {
    const response = await Auth.currentAuthenticatedUser();
    return response;
  } catch (error) {
    if (error === "The user is not authenticated") {
      return false;
    }
    captureException({
      key: "component",
      value: "authcontext",
      error,
      extra: null,
    });
    return false;
  }
};

const normalizeUser = (user) => {
  // Vado a creare un oggetto attributes, necessario disattivare
  // eslint perchè devo modificare obbligatoriamente questo oggetto.
  // eslint-disable-next-line no-param-reassign
  user.attributes = {
    ...user?.challengeParam?.userAttributes,
  };
  return user;
};

export const useAuthProvider = (): AuthContext => {
  const [currentAuthState, setAuthState] = useState<AuthState | null>();
  const [user, setUser] = useState();
  const [userEmail, setUserEmail] = useState();

  const setupAuthListener = () => {
    Hub.listen("auth", (data) => {
      const { payload } = data;
      if (payload.event === "signIn") {
        // Se arrivo da un flusso di force_change_password l'oggetto Cognito è leggermente diverso e va normalizzato
        const normalizedUser = payload?.data?.challengeParam
          ? normalizeUser(payload.data)
          : payload?.data;
        setAuthState("signedin");
        setUser(normalizedUser);
        Sentry.setUser(normalizedUser?.attributes?.sub);
      }
      if (payload.event === "signOut") {
        setUser(null);
        setAuthState("signin");
      }
    });
  };

  useEffect(() => {
    async function checkUserAuth() {
      const response = await isAuthenticated();
      if (response) {
        setUser(response);
        setAuthState("signedin");
      } else {
        setUser(null);
        setAuthState("signin");
      }
    }
    checkUserAuth();
    setupAuthListener();
  }, []);

  const updateUserAttributes = async (attributes: object) => {
    try {
      await Auth.updateUserAttributes(user, attributes);
      const response = await Auth.currentAuthenticatedUser();
      setUser(response);
    } catch (err) {
      captureException({
        key: "component",
        value: "updateUserAttributes",
        error: err,
        extra: null,
      });
    }
  };

  return {
    currentAuthState,
    user,
    changeAuthState: setAuthState,
    userEmail,
    setUserEmail,
    setUser,
    updateUserAttributes,
  };
};

export function AuthProvider({ children }) {
  const auth = useAuthProvider();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
