import { Auth } from "aws-amplify";
import EmailSupportBox from "components/Auth/EmailSupportBox";
import Box from "components/Layout/Box";
import { useState } from "react";
import Button from "components/Forms/Button";
import captureException from "helpers/sentryHelper";

export interface componentProps {
  userEmail?: string;
}

function ResendConfirmationCode({ userEmail }: componentProps) {
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);

  const resendCode = async () => {
    try {
      setLoading(true);
      await Auth.resendSignUp(userEmail);
      setText("success");
      setLoading(false);
    } catch (error) {
      switch (error.code) {
        case "LimitExceededException":
        case "QuotaExceededError":
          setText("Hai superato il limite di tentativi. Riprova più tardi");
          break;
        case "UserNotFoundException":
          setText("Link non valido o scaduto");
          break;
        case "CodeDeliveryDetails":
          setText("Utente inesistente");
          break;
        case "InvalidParameterException":
          if (error?.message === "User is already confirmed.") {
            setText("Link non valido o scaduto");
            break;
          }
          setText("Si è verificato un errore, riprova più tardi");
          captureException({
            key: "component",
            value: "resendconfirmationcode",
            error,
            extra: null,
          });
          break;
        default:
          setText("Si è verificato un errore, riprova più tardi");
          captureException({
            key: "component",
            value: "resendconfirmationcode",
            error,
            extra: null,
          });
          break;
      }
    }
  };
  return (
    <>
      <Box className="w-full sm:w-1/2 lg:w-1/3 max-w-[400px] sm:min-w-[400px] mx-auto">
        {text && text !== "success" ? (
          <span className="text-gray-darker text-6xl">{text}</span>
        ) : (
          <>
            {text === "success" ? (
              <p className="text-gray-darker text-6xl">
                Ti abbiamo inviato le istruzioni per verificare la tua email.
                Controlla la tua casella di posta. <br />
                Non ti è arrivata nessuna email? Prova a richiedere nuovamente
                l&apos;invio cliccando su &quot;Verifica email&quot;.
              </p>
            ) : (
              <p className="text-gray-darker text-6xl">
                La tua email non risulta ancora verificata. Se vuoi verificarla
                adesso clicca sul pulsante in basso.
              </p>
            )}
            <div className="text-center mt-4">
              <Button
                label="Verifica email"
                className="btn btn-primary"
                onClick={resendCode}
                loading={loading}
                disabled={loading}
              />
            </div>
          </>
        )}
      </Box>
      <EmailSupportBox />
    </>
  );
}
export default ResendConfirmationCode;
