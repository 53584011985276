import { API } from "aws-amplify";
import captureException from "helpers/sentryHelper";

export async function checkUserEmail(email: string): Promise<boolean> {
  try {
    await API.post("PublicAPI", "", {
      body: { email: email.toLowerCase() },
    });
    return true;
  } catch (error) {
    if (error.response) {
      const { data } = error.response;
      console.error("checkUserEmail:", data.message);
      return false;
    }

    if (error.request) {
      captureException({
        key: "component",
        value: "checkUserEmail",
        error,
        extra: {
          email: email.toLowerCase(),
        },
      });
      console.error(error.request);
      return false;
    }
    captureException({
      key: "component",
      value: "checkUserEmail",
      error,
      extra: {
        email: email.toLowerCase(),
      },
    });
    console.error(error.message);
    return false;
  }
}

const defaultExport = { checkUserEmail };

export default defaultExport;
