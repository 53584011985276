import {
  useState,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react";

export interface CookiePreferences {
  required?: boolean;
  analytics?: boolean;
  profile?: boolean;
}

const getCookie = (): string => {
  if (typeof document === "undefined") {
    return "";
  }
  const name = "cookiepolicy=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookie = decodedCookie.split("; ");
  for (let i = 0; i < cookie.length; i += 1) {
    if (cookie[i].indexOf(name) > -1) {
      return cookie[i].substring(name.length, cookie[i].length);
    }
  }
  return "";
};

const initializeState = (): CookiePreferences => {
  if (
    typeof document === "undefined" ||
    document.cookie.indexOf("cookiepolicy") === -1
  ) {
    return {
      required: true,
      analytics: false,
      profile: false,
    };
  }
  const currentCookie = getCookie().split(",");
  return currentCookie.reduce((state, cookieType) => {
    if (["required", "analytics", "profile"].indexOf(cookieType) !== -1) {
      state[cookieType] = true; // eslint-disable-line no-param-reassign
    }
    return state;
  }, {});
};

export const useCookies = (): [
  CookiePreferences,
  Dispatch<SetStateAction<CookiePreferences>>
] => {
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [preferences, setPreferences] =
    useState<CookiePreferences>(initializeState);

  const createCookie = useCallback((): string => {
    const keys = Object.keys(preferences);
    const newValue = keys.reduce((accumulator, cookieType) => {
      if (preferences[cookieType]) {
        accumulator.push(cookieType);
      }
      return accumulator;
    }, []);
    // Imposto la scadenza del cookie
    const expires = new Date(Date.now() + 1000 * 60 * 60 * 24 * 365 * 10);
    return `cookiepolicy=${newValue.join(
      ","
    )};expires=${expires.toUTCString()};path=/`;
  }, [preferences]);

  useEffect(() => {
    if (isLoaded && typeof document !== "undefined") {
      document.cookie = createCookie();
    }
    setLoaded(true);
  }, [preferences]);

  return [preferences, setPreferences];
};

export const useCookieScript = (preferences: CookiePreferences): void => {
  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle, no-multi-assign
    const _hsq = (window._hsq = window._hsq || []);
    if (preferences.profile) {
      _hsq.push(["doNotTrack", { track: true }]);
      return;
    }
    _hsq.push(["doNotTrack"]);
  }, [preferences.profile]);
};

const cookieObject = {
  useCookies,
  useCookieScript,
};

export default cookieObject;
