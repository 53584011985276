import React, { useState, useEffect } from "react";
import Image from "next/image";
import imgLoader from "helpers/urlHelper";
import { useRouter } from "next/router";
import { useAppContext } from "context/App";
import { useCookies, useCookieScript } from "./cookieHook";
import closeImg from "./close.svg";
import CookieBarContent from "./cookieBarContent";
import CookieBarOptions from "./cookieBarOptions";

function CookieBar() {
  const router = useRouter();
  const { openCookiebar, setOpenCookiebar } = useAppContext();

  const [currentView, switchView] = useState("content");
  const [preferences, setPreferences] = useCookies();
  useEffect(() => {
    if (
      typeof document !== "undefined" &&
      document.cookie.indexOf("cookiepolicy") === -1
    ) {
      setOpenCookiebar(true);
    }
  }, []);
  useCookieScript(preferences);

  const updatePreferences = (newPreferences) => {
    setPreferences({
      ...newPreferences,
    });
    setOpenCookiebar(false);
    router.reload();
  };

  const closeConditionalCookiebar = () => {
    if (typeof document === "undefined") {
      return;
    }
    if (document.cookie.indexOf("cookiepolicy") === -1) {
      setPreferences({
        required: true,
        analytics: false,
        profile: false,
      });
      router.reload();
      return;
    }
    setOpenCookiebar(false);
  };

  const acceptAllAndClose = () => {
    setPreferences({
      required: true,
      analytics: true,
      profile: true,
    });
    setOpenCookiebar(false);
    router.reload();
  };

  if (!openCookiebar) {
    return null;
  }

  if (typeof document === "undefined") {
    return null;
  }

  return (
    <div className="cookie-bar cookie-bar-half-mobile">
      <div className="relative banner-container">
        <button
          className="cookie-bar__close"
          onClick={closeConditionalCookiebar}
        >
          <span className="icon">
            {document.cookie.indexOf("cookiepolicy") === -1 &&
            currentView === "options" ? (
              ""
            ) : (
              <div data-action="close">
                <Image src={closeImg} loader={imgLoader} alt="close bar" />
              </div>
            )}
          </span>
        </button>
        {currentView === "content" && (
          <CookieBarContent
            onAccept={acceptAllAndClose}
            changeView={switchView}
          />
        )}
        {currentView === "options" && (
          <CookieBarOptions
            closeBar={() => setOpenCookiebar(false)}
            onUnmount={updatePreferences}
            preferences={preferences}
          />
        )}
      </div>
    </div>
  );
}

export default CookieBar;
