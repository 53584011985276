import CustomAuthenticator from "components/Auth/CustomAuthenticator";
import Head from "next/head";
import { AuthProvider } from "context/Auth";
import { AppProvider } from "context/App";
import { ApolloProvider } from "@apollo/client";
import "../styles/globals.scss";
import Script from "next/script";
import CookieBar from "components/Coookiebar/cookieBar";
import { useCookies } from "components/Coookiebar/cookieHook";
import AppConfig from "../AppConfig";

AppConfig.configure();
function MyApp({ Component, pageProps }) {
  const [preferenceCookies] = useCookies();
  return (
    <AuthProvider>
      <ApolloProvider client={AppConfig.getClient()}>
        <>
          <Head>
            <title>Area utenti</title>
            <meta name="description" content="Area utenti" />
            <link rel="icon" href="/favicon.ico" />
          </Head>
          {process.env.NEXT_PUBLIC_ENV &&
            process.env.NEXT_PUBLIC_ENV !== "local" && (
              <>
                <Script id="google-tag-manager" strategy="afterInteractive">
                  {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer',${
                process.env.NEXT_PUBLIC_ENV === "production"
                  ? "'GTM-KVGR3V'"
                  : "'GTM-K2SRN6W'"
              });`}
                </Script>
                <Script src="https://js.hs-scripts.com/3901390.js" />
              </>
            )}
          {process.env.NEXT_PUBLIC_ENV &&
            process.env.NEXT_PUBLIC_ENV !== "local" &&
            preferenceCookies.profile && (
              <Script id="hotjar" strategy="afterInteractive">
                {`
                      (function(h,o,t,j,a,r){
                        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                        h._hjSettings={hjid:3064001,hjsv:6};
                        a=o.getElementsByTagName('head')[0];
                        r=o.createElement('script');r.async=1;
                        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                        a.appendChild(r);
                        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                        `}
              </Script>
            )}

          <AppProvider>
            <CustomAuthenticator>
              <Component {...pageProps} />
              <CookieBar />
            </CustomAuthenticator>
          </AppProvider>
        </>
      </ApolloProvider>
    </AuthProvider>
  );
}

export default MyApp;
