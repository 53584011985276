import React, { useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { useRouter } from "next/router";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "components/Layout/Box";
import Input from "components/Forms/Input";
import Button from "components/Forms/Button";
import { Auth } from "aws-amplify";
import EmailSupportBox from "components/Auth/EmailSupportBox";
import captureException from "helpers/sentryHelper";
import { AuthViewProps } from "../CustomAuthenticator/types";

const schema = yup.object({
  firstname: yup.string().required("Campo richiesto."),
  lastname: yup.string().required("Campo richiesto."),
  company: yup.string().required("Campo richiesto."),
  email: yup.string().required("Campo richiesto.").email("Email non valida."),
  password: yup.string().required("Campo richiesto."),
  confirmpassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Le password devono essere uguali"),
});

function Signup({ changeAuthState, userEmail }: AuthViewProps) {
  const [formError, setFormError] = useState(null);
  const { asPath, query } = useRouter();
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const goToLogin = () => {
    changeAuthState("signin");
  };

  const trySignup: SubmitHandler<FieldValues> = async ({
    email,
    password,
    firstname,
    lastname,
    company,
    otherProps,
  }) => {
    try {
      const lowercaseEmail = email.toLowerCase();
      await Auth.signUp({
        username: lowercaseEmail,
        password,
        attributes: {
          email: lowercaseEmail,
          given_name: firstname,
          family_name: lastname,
        },
        clientMetadata: {
          ...otherProps,
          firstName: firstname,
          lastName: lastname,
          company,
          redirectTo: asPath.length > 0 && asPath !== "/" ? asPath : null,
          eventId: query.fromDiretta ? query.eventId : null,
        },
      });
      changeAuthState("signedup");
      return;
    } catch (error) {
      if (error.name) {
        switch (error.name) {
          case "UsernameExistsException":
            setFormError(
              <span className="text-base text-red-700 inline-block">
                Esiste già un&apos;utenza con questa email. Clicca{" "}
                <button
                  className="font-semibold bg-transparent outline-none"
                  onClick={goToLogin}
                >
                  qui
                </button>{" "}
                per effettuare l&apos;accesso
              </span>
            );
            break;
          case "InvalidPasswordException":
            setFormError(
              <span className="text-xs text-red-700 inline-block mt-1">
                Password non valida. La password deve contenere almeno 8
                caratteri, un numero, una lettera minuscola e una lettera
                maiuscola
              </span>
            );
            break;
          case "InvalidParameterException":
            if (error?.message?.indexOf("password") !== -1) {
              setFormError(
                <span className="text-xs text-red-700 inline-block mt-1">
                  Password non valida. La password deve contenere almeno 8
                  caratteri, un numero, una lettera minuscola e una lettera
                  maiuscola
                </span>
              );
              break;
            }
            setFormError(
              <span className="text-xs text-red-700 inline-block mt-1">
                Si è verificato un errore, riprova più tardi
              </span>
            );
            break;
          default:
            captureException({
              key: "component",
              value: "signup",
              error,
              extra: null,
            });
            setFormError(
              <span className="text-xs text-red-700 inline-block mt-1">
                Si è verificato un errore, riprova più tardi
              </span>
            );
            break;
        }
      }
    }
  };

  return (
    <>
      <Box className="w-full sm:w-1/2 lg:w-1/3 mx-auto max-w-[400px]">
        <form
          className="w-full"
          method="POST"
          onSubmit={handleSubmit(trySignup)}
        >
          <p className="text-gray-darker">
            La tua email non risulta ancora registrata. Compila i seguenti campi
            per creare un account.
          </p>
          <p className="subtitle2 text-gray-dark my-2">
            Leggi l&apos;
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.networkdigital360.it/privacy?__hstc=44338619.29fe862c7835f4f659bcad34cd98ffa4.1614333474957.1618227012708.1618560166137.5&__hssc=44338619.3.1618560166137&__hsfp=3716040236"
            >
              informativa sulla privacy
            </a>
          </p>
          <div className="flex max-w-full flex-wrap mt-2">
            <p className="text-gray-dark subtitle1 mr-2">{userEmail}</p>
            <button
              className="text-primary-dark subtitle2 font-bold bg-transparent"
              onClick={goToLogin}
              type="button"
            >
              modifica
            </button>
          </div>
          <Input
            name="firstname"
            className="w-full"
            register={register}
            type="text"
            placeholder="Nome"
            label="Nome *"
            error={
              typeof errors?.firstname?.message === "string"
                ? errors?.firstname?.message
                : ""
            }
          />
          <Input
            name="lastname"
            className="w-full"
            register={register}
            type="text"
            placeholder="Cognome"
            label="Cognome *"
            error={
              typeof errors?.lastname?.message === "string"
                ? errors?.lastname?.message
                : ""
            }
          />
          <Input
            name="company"
            className="w-full"
            register={register}
            type="text"
            placeholder="Azienda"
            label="Azienda *"
            error={
              typeof errors?.company?.message === "string"
                ? errors?.company?.message
                : ""
            }
          />
          <Input
            name="email"
            register={register}
            type="hidden"
            value={userEmail}
          />
          <Input
            name="password"
            className="w-full"
            register={register}
            type="password"
            placeholder="Password"
            label="Password *"
            withHideIcon
            error={
              typeof errors?.password?.message === "string"
                ? errors?.password?.message
                : ""
            }
          />
          <Input
            name="confirmpassword"
            className="w-full"
            register={register}
            type="password"
            placeholder="Conferma password"
            label="Conferma password *"
            withHideIcon
            error={
              typeof errors?.confirmpassword?.message === "string"
                ? errors?.confirmpassword?.message
                : ""
            }
          />
          {formError}
          <Button
            className="w-full btn btn-primary mt-4"
            type="submit"
            label="Registrati"
            loading={isSubmitting}
          />
        </form>
      </Box>
      <EmailSupportBox />
    </>
  );
}

export default Signup;
