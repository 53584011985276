import React from "react";
import clsx from "clsx";

export interface componentProps {
  className?: string;
}

function Divider({ className }: componentProps) {
  return <div className={clsx("divider", className)} />;
}

export default Divider;
